@import '../../../../scss/theme-bootstrap';

.tabbed-products-block {
  padding: 0;
  margin: 0 auto;
  @include breakpoint($bp--large-up) {
    padding-bottom: 73px;
  }
  .product-brief-v2 {
    width: auto;
    @if $cr21_mpp == false {
      .product-brief__shades {
        .product-brief-shades {
          .slick-prev,
          .slick-next {
            top: 13px;
            @include breakpoint($bp--medium-up) {
              top: 9px;
            }
          }
        }
      }
    }
    @if $cr21 == false {
      .product-brief__shades {
        position: relative;
        .product-brief-shades {
          .slick-next,
          .slick-prev {
            top: -25px;
            @include breakpoint($bp--medium-up) {
              top: -15px;
            }
            .icon {
              vertical-align: middle;
            }
          }
        }
      }
    }
    .product__rating-stars-rating {
      #{$ldirection}: 0;
    }
    .has-alt-image {
      &:hover {
        .product-brief__image-img {
          display: none;
          &--alt-image {
            display: block;
            opacity: 1;
            visibility: visible;
            position: static;
          }
        }
      }
    }
  }
  &__inner {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
  }
  &__tabs {
    display: flex;
    margin: 10px auto 0 auto;
    text-align: #{$ldirection};
    border-bottom: 1px solid $color--black;
    .tabbed-products-block--light-controls & {
      border-bottom: 1px solid $color--white;
    }
  }
  &__tab {
    padding: 16px 30px;
    color: $color--black;
    cursor: pointer;
    text-transform: uppercase;
    border-bottom: none;
    @include breakpoint($bp--large-up) {
      padding: 16px 100px;
    }
    .tabbed-products-block--light-controls & {
      color: $color--white;
    }
    &:visited {
      border-bottom: none;
      color: $color--black;
      .tabbed-products-block--light-controls & {
        border-bottom: 1px solid $color--white;
        color: $color--white;
      }
    }
    &:not(.current):hover {
      border-bottom-color: $color--black solid 1px;
      color: $color--black;
      .tabbed-products-block--light-controls & {
        border-bottom: 1px solid $color--white;
        color: $color--white;
      }
    }
    &.current {
      border-bottom: $color--black solid 1px;
      color: $color--black;
      .tabbed-products-block--light-controls & {
        border-bottom: 1px solid $color--white;
        color: $color--white;
      }
    }
  }
  &__header {
    padding: 2em 0;
    &.content-block {
      @include breakpoint($bp--large-up) {
        width: 50%;
        padding-block-end: 0;
      }
    }
  }
  &__content-item {
    height: 0;
    width: 100%;
    overflow: hidden;
    transition: opacity 1s ease-in-out;
    opacity: 0;
    &.current {
      height: auto;
      overflow: visible;
      opacity: 1;
    }
  }
  &__product-brief-carousel {
    position: relative;
    .slick-slider {
      margin: 0;
      padding: 0;
      .slick-list {
        padding: 0;
        width: 100%;
        margin: 0;
        .tabbed-products-block--carousel-peeking & {
          @include swap_direction(padding, 0 10% 0 0);
          @include breakpoint($bp--large-up) {
            @include swap_direction(padding, 0 5% 0 0);
          }
          @include breakpoint($bp--xlarge-up) {
            @include swap_direction(padding, 0 3% 0 0);
          }
        }
      }
    }
    .slick-track {
      display: flex;
      flex-direction: row;
    }
    &.grid--mpp {
      padding: 0;
    }
    &.grid--mpp__carousel {
      .grid--mpp__items {
        box-sizing: border-box;
        display: flex;
        .grid--mpp__item {
          @include swap_direction(margin, 0 10px 0 0);
          height: auto;
          background-color: $color--white;
          @if $cr21-images == true {
            background-color: $color-cta-grey--light;
          }
          @if $cr21_mpp == false {
            background-color: $color--white;
          }
          padding: 0 0 10px;
          max-width: 90vw;
          @include breakpoint($bp--large-up) {
            @include swap_direction(margin, 0 17px 0 0);
          }
          .product__image-medium {
            max-width: inherit;
          }
        }
      }
    }
  }
  &--carousel-large-4-up,
  &--carousel-medium-4-up {
    .product-brief__badge {
      @include breakpoint($bp--large-up) {
        margin: 0;
      }
    }
    .product-brief__name-link {
      @include breakpoint($bp--large-up) {
        min-height: 26px;
      }
    }
    .product-brief__shades {
      @include breakpoint($bp--large-up) {
        margin-top: 5px;
      }
    }
  }
}

.tabbed-products-block__carousel-arrows {
  display: none;
  position: absolute;
  margin-top: -50px;
  #{$rdirection}: 5px;
  top: 0;
  width: 120px;
  justify-content: space-between;
  @include breakpoint($bp--large-up) {
    display: flex;
  }
  .slick-next,
  .slick-prev {
    width: 48px;
    height: 48px;
    position: relative;
    top: -12px;
    &:before {
      content: '';
    }
  }
  // dark and light utility classes, dark is default
  .slick-prev {
    right: auto;
    left: 0;
    background: url('#{$base-theme-path}svg-icons/src/icon--arrow-left-lrg--black.svg') no-repeat;
  }
  .slick-next {
    right: 0;
    left: auto;
    background: url('#{$base-theme-path}svg-icons/src/icon--arrow-right-lrg--black.svg') no-repeat;
  }
  .tabbed-products-block--light-controls & {
    .slick-prev {
      background: url('#{$base-theme-path}svg-icons/src/icon--arrow-left-lrg--white.svg') no-repeat;
    }
    .slick-next {
      background: url('#{$base-theme-path}svg-icons/src/icon--arrow-right-lrg--white.svg') no-repeat;
    }
  }
}

[dir=rtl] {
  .tabbed-products-block__carousel-arrows {
    .slick-prev {
      right: auto;
      left: 0;
    }
    .slick-next {
      right: 0;
      left: auto;
    }
  }
}

// We have to overridee a lot of global slick styles...
.tabbed-products-block__carousel-dots {
  display: block;
  margin: 35px auto;
  @include breakpoint($bp--large-up) {
    display: none;
  }
  .slick-dots {
    position: relative;
    bottom: auto;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    text-align: center;
    color: $color--black;
    .tabbed-products-block--light-controls & {
      color: $color--white;
    }
    // override global styles here
    > li {
      display: inline-block;
      padding: 0;
      height: 8px;
      width: 8px;
      margin: 0 2px;
      button {
        cursor: default;
        height: 8px;
        width: 8px;
        padding: 0;
      }
      button::before {
        height: 6px;
        line-height: 6px;
        width: 6px;
        border: 1px solid $color--black;
        border-radius: 10px;
        color: transparent;
        background-color: transparent;
        opacity: 1;
        .tabbed-products-block--light-controls & {
          border-color: $color--white;
        }
      }
      &.slick-active {
        button::before {
          background-color: $color--black;
          .tabbed-products-block--light-controls & {
            background-color: $color--white;
          }
        }
      }
    }
  }
}
